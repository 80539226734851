<template>
    <vs-card class="consent-table">
  
        <div slot="header" class="flex items-center justify-between w-full">
          <h3>Consent Forms</h3>
          <vs-button 
              @click="redirect()"
              icon-pack="feather"
              icon="icon-plus"> Add </vs-button>
        </div>
        
        <vs-table :max-items="dataTableParams.limit" pagination :data="forms" class="mt-2 lg:mt-4">
            <template slot="thead">
                <vs-th>Consent Form</vs-th>
                <vs-th>Visibility</vs-th>
                <vs-th>Actions</vs-th>
            </template>
            <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td data-label="Name" :data="data[indextr].name">
                      <button @click="redirect(data[indextr]._id)" class="text-primary">
                          <small class="block text-sm text-gray-700 mb-1">{{ data[indextr].isDefault ? 'Fresh Clinics' : 'Custom' }}</small>
                           {{data[indextr].name}}
                      </button>
                    </vs-td>

                    <vs-td data-label="Active" :data="data[indextr].active">
                        {{isActive(data[indextr].status) ? 'Visible' : 'Hidden'}}
                    </vs-td>

                    <vs-td data-label="Action" :data="data[indextr]._id">
                      <div class="flex items-center space-x-2">
                        <vs-button
                            type="border"
                            size="small"
                            icon-pack="feather"
                            @click="redirect(data[indextr]._id)"
                            :icon="defaultConsentForm(data[indextr].isDefault)"
                        ></vs-button>
                        <vs-button
                            v-if="isDefaultAndOrgOwner(data[indextr].isDefault, data[indextr].userId || null)"
                            type="border"
                            size="small"
                            icon-pack="feather"
                            @click="confirmDeleteRecord(data[indextr]._id)"
                            icon="icon-trash"
                            color="danger"
                        ></vs-button>
                      </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>

        <vs-popup :active.sync="popUpDelete" title="Delete Consent Form" >
              <p align="center">Are you sure you want to delete this Consent form?</p>
              <br />
              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" vs-mt="6">
                <vs-col vs-type="flex" vs-align="left" vs-w="6">
                  <div class="w-full m-5" align="right">
                    <vs-button
                      size="lg"
                      color="primary"
                      @click="deleteDetailHandler"
                    >Yes</vs-button>
                  </div>
                </vs-col>
                <vs-col vs-type="flex" vs-align="left" vs-w="6">
                  <div class="w-full m-5">
                    <vs-button size="lg" color="danger" @click="popUpDelete=false">Cancel</vs-button>
                  </div>
                </vs-col>
              </vs-row>
        </vs-popup>
    </vs-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},
  data:() => ({
    dataTableParams: {
      dir: "asc",
      page: 1,
      limit: 25
    },
    limitOptions: [5, 25, 50, 100],
    popUpDelete: false,
    popupConsentData: {
      id: ''
    }
  }),
  methods: {
    ...mapActions("consentForms", [
      "createDefaultConsentForms", "fetchConsentForms", "deleteConsentForm"
    ]),
    defaultConsentForm(isDefault){
      if(isDefault) return "icon-eye"
      return "icon-edit"
    },
      
    confirmDeleteRecord(id) {
      this.popupConsentData.id = id;
      this.popUpDelete=true;
    },
    redirect(id = null) {
      const userType = JSON.parse(
          localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ).userType;
      const role = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );
      let path = "org-owner";

      if (role !== "Org Owner") path = userType

      const mode = id ? `${id}/details` : "create"

      this.$router.push(`/${path}/consent-forms/${mode}`)
    },
    isActive(isActive) {
      return isActive ? 'Active' : 'Inactive';
    },
    async createDefaultAndFetch() {
      const org_id = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)

      const payload = {};

      if (org_id) payload['orgId'] = org_id

      // await this.createDefaultConsentForms(payload)
      await this.fetchConsentForms(payload)
      
    },
    async deleteDetailHandler() {
        if (!this.popUpDelete) return

        await this.deleteConsentForm(this.popupConsentData.id)
          .then(res => {
            this.popUpDelete=false;
            this.createDefaultAndFetch();
            this.$vs.notify({
              title: "Success",
              text: "Consent Form Removed successfully.",
              color: "success"
            });
          })
          .catch(err => {
            this.popUpDelete = false;
            this.$vs.notify({
              title: err.data.title,
              text: err.data.message,
              color: "danger"
            });
          })
    },
    isDefaultAndOrgOwner(isDefault, userId) {
      const role = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`)
      return (role === 'Org Owner' && !isDefault) || (!isDefault && userId === this.$store.state.AppActiveUser.id);
    }
  },
  computed: {
    ...mapState("consentForms", ["forms"])
  },
  created() {
    this.createDefaultAndFetch()
  }
}
</script>

<style lang="scss">
.consent-table {

  .con-select {
    width: 100px !important;
  }

  .vs {
    &-con {
      &-tbody {
        border: none !important;
      }
    }

    &-card--content {
      padding: 0;
      border-top: 1px solid #f8f8f8 !important;
    }  

    &-select {
      &--input,
      &--item {
        font-family: inherit !important;
      }
    }

    &-table {
        &--tbody-table {
          min-width: unset !important;
        }
        &--header {
          padding: 0 15px;
        }
        td {
          &:first-child {
            width: 85%;

            button {
              width: 100%;
              cursor: pointer;
              appearance: none;
              border: none;
              background: none;
              text-align: left;
              font-family: inherit;
              color: inherit;
              font-size: 1rem;
              font-family: inherit !important;
            }
          }
        }
    }
  }

  .vs-con-table .vs-table--pagination {
    margin-top: .5rem;
    padding: 0 10px;
  }

  @media screen and (max-width: 600px) { 
    .vs-con-table .vs-table--pagination {
      margin-top: 0;
    }
    
    .vs {
      &-table {
        &--header {
          display: none;
        }

        &--thead {
          border: none;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }

        tr {
          &:not(:last-child) {
            border-bottom: 1px solid #f8f8f8;
            display: block;
          }
        }

        td {
          border: none;
          display: flex;
          text-align: left;
        
          &:first-child {
            width: 100%;
          }

          &:not(:first-child) {
            display: none;
          }
          & button {
            pointer-events: auto !important;
            position: relative;
            width: 90%;
          }
          & span {
            display: block;
            flex: 1;
            width: 100%;
            position: relative;
          
            &::after {
              content: '';
              font-weight: bold;
              text-transform: uppercase;
              background-size: contain;
              background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNNy4zMyAyNGwtMi44My0yLjgyOSA5LjMzOS05LjE3NS05LjMzOS05LjE2NyAyLjgzLTIuODI5IDEyLjE3IDExLjk5NnoiLz48L3N2Zz4=");
              width: 10px;
              height: 10px;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
    
  }
}
</style>
